import React, { useState } from 'react';
import { saveExpense, updateExpense } from '../../api/expenses';
import { formatDateAsMMDDYYYY } from '../../utils/expenses';

const Create = ({
  user,
  header, //boolean - whether or not to show header text
  expense,  //Expense - the specific expense if you're updating
  close, //function - what to run at close
  update, //boolean - if it's an update(true) or new expense(false)
  onHide, //function - method to run on submit
  retreiveExpenses //function - way to refresh the expense array
}) => {
  const [buttonText, setButtonText] = useState('Submit')
  const [formData, setFormData] = useState(expense ? expense : {
    id: '',
    amount: "$",
    user: user,
    category: '',
    timestamp: formatDateAsMMDDYYYY(new Date()),
    merchant: '',
    description: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission, e.g., send the data to the server
    if (update) {
      updateExpense(formData).then(res => {
        retreiveExpenses()
        closeComp();
        if (onHide) {
          onHide()
        }
      }).catch(err => {console.log(err); alert("Error saving data")})
    } else {
      saveExpense(formData).then(res => {
        if (res.status == 201) {
            retreiveExpenses()
            setButtonText('Saved')
            closeComp()
        }
    }).catch(err => console.log(err))
    }
    setButtonText('Saving...');
  };

  const closeComp = () => {
    setFormData({
        id: '',
        amount: '$',
        user: '',
        category: '',
        timestamp: '',
        merchant: '',
        description: '',
    })
    setButtonText('Submit')
    close()
  }

  return (
    <div>
      {header && <h2>Create Expense</h2>}
      <form onSubmit={handleSubmit} className='create-expense-form'>
        {/*<input
          key="id"
          type="text"
          placeholder="ID"
          name="id"
          value={formData.id}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />*/}
        <input
          key="amount"
          type="number"
          placeholder="Amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />
        <input
          key="user"
          type="text"
          placeholder="User"
          name="user"
          value={formData.user}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />
        <input
          key="category"
          type="text"
          placeholder="Category"
          name="category"
          value={formData.category}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />
        <input
          key="timestamp"
          type="text"
          placeholder="Timestamp"
          name="timestamp"
          value={formData.timestamp}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />
        <input
          key="merchant"
          type="text"
          placeholder="Merchant"
          name="merchant"
          value={formData.merchant}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
        />
        <input
          key="description"
          type="text"
          placeholder="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className='create-expense-input create-expense-id'
          maxLength={36}
        />
        <button type="submit">{buttonText}</button>
      </form>
    </div>
  );
};

export default Create;
