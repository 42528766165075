import { generateUUID } from "../utils/general";

const local = false;

const route = local ? 'http://localhost:8080/api' : 'https://api.bydallas.com/api'

export const getExpenses = async () => {
    try {
        const response = await fetch(`${route}/expenses`);
        if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        return data
    } catch (error) {
        console.error('Error while fetching expenses:', error);
    }
}

export const saveExpense = async (data) => {

      if (data.id === "" || !data.id) {
        //data.id = generateUUID();
      }
      
      let response = await fetch(`${route}/expense`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      return response;
}

export const updateExpense = async (data) => {
      
  let response = await fetch(`${route}/expense`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  console.log(response);
  return response;
}