import React, {useState, useEffect} from 'react';
import './App.css';
import List from './components/List/List';
import Home from './components/Expense/Home' 
import Create from './components/Create/Create';
import {getExpenses} from './api/expenses'
import { sortTimestampNewest } from './utils/expenses';
import { expensesArray } from './api/fakeData/data';

function App() {

  const [create, setCreate] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [user, setUser] = useState("dallas");

  const retreiveExpenses = () => {
    console.log("getting expenses")
    getExpenses().then(res => {
      console.log("got expenses")
        setExpenses(sortTimestampNewest(res));
        if (res === undefined) {
            setExpenses(sortTimestampNewest(expensesArray));
        }
    }).catch(err => console.log(err));
}

  useEffect(()=> {
    retreiveExpenses();
  },[])

  return (
    <div className="App">
      <h1>Hello Expenses</h1>
      <p>This is an app to better track personal expenses. As Benjamin Franklin says in 'The Way to Wealth', "beware of little expenses; a small leak will sink a great ship."</p>
      <button onClick={()=>setCreate(!create)}>Create</button>
      {create && <Create user={user} retreiveExpenses={retreiveExpenses} close={()=>setCreate(false)} />}
      <List expenses={expenses} />
    </div>
  );
}

export default App;