import React from 'react'
import './Expense.css'
import Create from '../Create/Create';

function EditExpenses({expense, show, onHide, retreiveExpenses}) {
  return (
    <div className='edit-expense' style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%"}}>
        <div className='edit-expense-container'>
            <div className='edit-expense-top-row'>
                <div className='edit-expense-title'>Edit Expense</div>
                <button className='edit-expense-top-close' onClick={onHide}>x</button>
            </div>
            <Create expense={expense} close={()=>{return;}} update={true} onHide={onHide} retreiveExpenses={retreiveExpenses} />
        </div>
    </div>
  );
}

export default EditExpenses;