import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'

const ListItem = ({expense}) => {
    return (
        <tr>
            
            <td style={{outline: '1px solid green'}}>
                <div style={{fontSize: '12pt', fontWeight: '300'}}>{expense.merchant} </div>
                <div style={{fontSize: '8pt', fontWeight: '300'}}>{expense.category}</div>
                <div style={{fontSize: '6pt', fontWeight: '200'}}>{expense.description}</div>
            </td>
            <td style={{textAlign: 'right' ,outline: '1px solid blue'}}>${expense.amount}</td>
        </tr>
    )
}

export default ListItem;