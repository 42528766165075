import React, {useState} from 'react'
import ListItem from './ListItem'
import { createDatesArrayMMDDYYYY } from '../../utils/expenses'

const List = ({expenses}) => {

    console.log("List Rendered")

    return (
        <table style={{textAlign: "left", overflow: "auto", maxWidth: '100vw'}}>

            {expenses && createDatesArrayMMDDYYYY(expenses).map(date => {
                let count = 0;
                return <>
                    <tr><td>
                        <span  style={{backgroundColor: 'lightgray', borderRadius: "1rem", paddingLeft: "6px", paddingRight: "6px", paddingTop: "2px", paddingBottom: "2px"}}>{date}</span>
                    </td></tr>
                    {expenses.slice(count, expenses.length).map((expense, index) => {
                            if (expense.timestamp == date) {
                                count++
                                return ( 
                                    <ListItem key={expense.id} expense={expense} />
                                )
                            }
                        })}
                </>
            })}
        </table>
    )
}

export default List;