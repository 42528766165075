export const createDatesArray = (arr) => {
    const dates = [];
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].timestamp.length >= 10) {
            const date = arr[i].timestamp.substring(0, 10);
            if (!dates.includes(date)) {
                dates.push(date);
            }
        }
    }
    dates.sort();
    dates.reverse();
    return dates;
}

export const createDatesArrayMMDDYYYY = (arr) => {
    let dates = [];
    for (let i = 0; i < arr.length; i++) {
        const date = arr[i].timestamp;
            if (!dates.includes(date)) {
                dates.push(date);
            }
    }
    dates = sortTimestampNewest(dates);
    return dates;
}

export const sortTimestampNewest = (arr) => {
    return arr.sort((a, b) => {
        return getTimestamp(b.timestamp) - (getTimestamp(a.timestamp));
    })
}

const getTimestamp = (dateString) => {

    // Parse the date string and create a Date object
    console.log(dateString)
    if (!dateString || dateString == undefined) {
        return 0;
    }
    const dateParts = dateString.split("/");
    const year = parseInt(dateParts[2], 10) + 2000; // Assuming 20th century, adjust if needed
    const month = parseInt(dateParts[0], 10) - 1; // Months are 0-indexed
    const day = parseInt(dateParts[1], 10);

    const dateObject = new Date(year, month, day);
    return dateObject;
}

export function formatDateAsMMDDYYYY(date) {
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month}/${day}/${year}`;
  }