export const expensesArray = [
    {
      id: "1",
      amount: 148.69,
      category: "groceries",
      description: "Weekly Groceries",
      merchant: "Trader Joes",
      timestamp: "2023-11-04",
      user: "dallas",
    },
    {
      id: "2",
      amount: 234.55,
      category: "utilities",
      description: "Monthly Utilities",
      merchant: "Electricity Company",
      timestamp: "2023-10-28",
      user: "dallas",
    },
    {
      id: "3",
      amount: 42.89,
      category: "dining",
      description: "Lunch with Friends",
      merchant: "Cafe Milano",
      timestamp: "2023-11-02",
      user: "dallas",
    },
    {
      id: "4",
      amount: 67.32,
      category: "transportation",
      description: "Gasoline",
      merchant: "Shell",
      timestamp: "2023-10-30",
      user: "dallas",
    },
    {
      id: "5",
      amount: 125.0,
      category: "entertainment",
      description: "Movie Night",
      merchant: "Cinema Paradiso",
      timestamp: "2023-11-01",
      user: "dallas",
    },
    // 15 more randomly generated expenses
    {
      id: "6",
      amount: 49.99,
      category: "groceries",
      description: "Snacks and Drinks",
      merchant: "Fresh Mart",
      timestamp: "2023-11-05",
      user: "dallas",
    },
    {
      id: "7",
      amount: 200.0,
      category: "rent",
      description: "Monthly Rent",
      merchant: "Apartment Complex",
      timestamp: "2023-11-06",
      user: "dallas",
    },
    {
      id: "8",
      amount: 32.75,
      category: "dining",
      description: "Breakfast Meeting",
      merchant: "The Breakfast Club",
      timestamp: "2023-11-07",
      user: "dallas",
    },
    {
      id: "9",
      amount: 45.25,
      category: "utilities",
      description: "Internet Bill",
      merchant: "ISP Company",
      timestamp: "2023-11-06",
      user: "dallas",
    },
    {
      id: "10",
      amount: 75.0,
      category: "transportation",
      description: "Metro Pass",
      merchant: "City Metro",
      timestamp: "2023-11-06",
      user: "dallas",
    },
    {
      id: "11",
      amount: 20.0,
      category: "entertainment",
      description: "Streaming Subscription",
      merchant: "Online Streaming Service",
      timestamp: "2023-11-05",
      user: "dallas",
    },
    {
      id: "12",
      amount: 90.0,
      category: "groceries",
      description: "Monthly Groceries",
      merchant: "Local Market",
      timestamp: "2023-11-05",
      user: "dallas",
    },
    {
      id: "13",
      amount: 60.0,
      category: "dining",
      description: "Dinner Date",
      merchant: "Fine Dining Restaurant",
      timestamp: "2023-11-05",
      user: "dallas",
    },
    {
      id: "14",
      amount: 35.0,
      category: "utilities",
      description: "Gas Bill",
      merchant: "Gas Company",
      timestamp: "2023-11-03",
      user: "dallas",
    },
    {
      id: "15",
      amount: 100.0,
      category: "entertainment",
      description: "Concert Tickets",
      merchant: "Local Concert Hall",
      timestamp: "2023-11-04",
      user: "dallas",
    },
    // Add more items here based on the structure provided
    // ...
  ];
  